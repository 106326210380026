import * as jQuery from "jquery";
import {searchCore, SearchParams} from "./search_core_new";
import {typeahead} from "./typeahead_new";

export let searchBar = {
    initialize(suggestURL: string, isOuterSearch: boolean, searchParams: SearchParams) {
        let $ = jQuery;
        // Typeahead cache
        function makeActionFunction(searchParams: SearchParams) {
            function actionFunction() {
                searchCore.doSearch(searchParams);
            }
            return actionFunction;
        }
        typeahead.initialize('search-input', suggestURL, true, 10, makeActionFunction(searchParams));

        // Handle number of rooms search input
        if (isOuterSearch) {
            $('input[id=input-num-people]').change(function () {
                //searchCore.doSearchOuter();
                return false;
            }).keypress(function (e) {
                if (e.which === 13) {
                    searchCore.doSearchOuter(searchParams);
                    return false;
                }
            });
        } else {
            $('input[id=input-num-people]').keypress(function (e) {
                if (e.which === 13) {
                    searchCore.doSearch(searchParams);
                    return false;
                }
            });
        }
        // Handle search bar
        $('#search-input').keypress(function (e) {
            if (e.which === 13) {
                searchCore.doSearch(searchParams);
            }
        });
        // Handle search clear icon
        $('#search-clear-icon').click(function () {
            $('#search-input').val('').attr('placeholder', 'Søk på by, område, hotell eller konferansested');
            //$('input[id=input-num-people]').val('').attr('Antall personer');
            searchCore.doSearchOuter(searchParams);
            return false;
        });
        // Handle search icon
        $("#search-icon").click(function () {
            searchCore.doSearchOuter(searchParams);
            return false;
        });
        // Handle new filters
        $('select[id=capacity]').change(function() {
            searchCore.doSearch(searchParams);
        });
        $('select[id=rooms]').change(function() {
            searchCore.doSearch(searchParams);
        });
        $('select[id=experiences]').change(function() {
            searchCore.doSearch(searchParams);
        });
    }
};
