//import * as $ from "jquery";
import typeAhead from 'typeahead-standalone';
import "../styles/typeahead.css";
//import 'typeahead-standalone/dist/basic.css';

declare global {
    interface Window {
        typeaheadCache: { [key: string]: { [key: string]: SearchResultItem[]; }; };
    }
}

type SearchResultItem = {
    name: string,
    category: string
}

type SearchResults = {
    results: SearchResultItem[]
}

export let typeahead = {
    /**
     * Sets up typeahead suggestions for the given input element.
     *
     * @param inputId - Selector string for input element.
     * @param suggestionsURL - URL used to get the suggestions.
     * @param showCategoryHeader - Will show headers for different result categories if true.
     * @param numItems - The number of suggestions to show.
     * @param actionFunction - Function that will be called when a suggestion is selected.
     */
    initialize: function (inputId: string, suggestionsURL: string, showCategoryHeader: boolean, numItems: number, actionFunction: () => void) {
        window.typeaheadCache = window.typeaheadCache || {};
        window.typeaheadCache[suggestionsURL] = window.typeaheadCache[suggestionsURL] || {};

        let inputElement = (document.getElementById(inputId) as HTMLInputElement);
        if (inputElement) {
            let instance = typeAhead({
                input: inputElement,
                highlight: true,
                hint: false,
                source: {
                    remote: {
                        url: suggestionsURL + '?q=%QUERY',
                        wildcard: '%QUERY',
                    },
                    keys: ["name"],
                    groupKey: "category"
                },
                limit: numItems,
                onSubmit: (e, selectedSuggestion) => {
                    if (selectedSuggestion) {
                        actionFunction();
                    }
                }
            });
            $('body').on("click", '.tt-suggestion', function () {
                actionFunction();
            });

        }
    }
};
