import * as jQuery from "jquery";
import { searchCore, SearchParams } from "./search_core_new";
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown";

export default class SearchFilters {
    constructor() {
    }

    initialize(searchParams: SearchParams) {
        let $ = jQuery;
        $('a.chain-item').click(function () {
            let id = $(this).attr('id');
            if (id) {
                searchParams.chainVal = parseInt(id.split('-')[2]);
                searchCore.doSearch(searchParams);
            }
            return false;
        });
        $('a.category-item').click(function () {
            let id = $(this).attr('id');
            if (id) {
                searchParams.categoryVal = parseInt(id.split('-')[2]);
                searchParams.parentCategoryVal = undefined;
                searchCore.doSearch(searchParams);
            }
            return false;
        });
        $('a.submenu').click(function () {
            let id = $(this).attr('id');
            if (id) {
                searchParams.parentCategoryVal = parseInt(id.split('-')[1]);
                searchParams.categoryVal = undefined;
                searchCore.doSearch(searchParams);
            }
            return false;
        });
        let dropdown = $(".dropdown");
        dropdown.on("hide.bs.dropdown", function () {
            $(this).find('.dropdown-toggle span').removeClass("caret-up");
        });
        dropdown.on("show.bs.dropdown", function () {
            $(this).find('.dropdown-toggle span').addClass('caret-up');
        });
        $('.dropdown-submenu .submenu').on("click mouseenter", function (e) {
            $(".dropdown-menu .dropdown-submenu .submenu").next('ul').hide();
            $(".dropdown-menu .dropdown-submenu .submenu").find("span").removeClass("caret-black-up");
            $(this).next('ul').toggle();

            /* check display for caret */
            if ($(this).next('ul').css('display') == 'none') {
                $(this).find('span').removeClass("caret-black-up");
            } else {
                $(this).find('span').addClass("caret-black-up");
            }
            /* check display for caret || */

            e.stopPropagation();
            e.preventDefault();
        });

        $(".category-dropdown").on("click", function () {
            // @ts-ignore
            $(this).find('.dropdown-toggle').dropdown("toggle");
            return false;
        });
    }
}

