import * as jQuery from "jquery";

export interface SearchParams {
    categoryVal: number | undefined,
    chainVal: number | undefined,
    parentCategoryVal: number | undefined,
    addToHotelRequestId: number | undefined,
    providerType: string,
    addMore: boolean
}

interface SearchResult {
    id: string,
    map: string,
    name: string,
    street: string,
    slug: string
}

interface SearchResponse {
    html: string,
    pagination_html: string,
    hits: SearchResult[],
    total: number,
    map_center: string,
    cinema_capacity_number: number
}

export let searchCore = {
    map: null,
    mapCenter: '',
    firstSearch: true,
    ignoreZoomEvent: false,
    $: jQuery,
    startResult: 0,
    shownResultsSize: 0,

    initialize: function (searchURL: string, innerSearchURL: string, restaurantSearchURL: string, askUsURL: string, findURL: string, startResult: number, mapLatLngNE: google.maps.LatLng, mapLatLngSW: google.maps.LatLng, prevZoom: number, paginationBox: JQuery, resultsBox: JQuery, shownResultsSize: number, google: any) {
        this.searchURL = searchURL;
        this.innerSearchURL = innerSearchURL;
        this.restaurantSearchURL = restaurantSearchURL;
        this.askUsURL = askUsURL;
        this.findURL = findURL;
        this.startResult = startResult;
        this.mapLatLngNE = mapLatLngNE;
        this.mapLatLngSW = mapLatLngSW;
        this.prevZoom = prevZoom;
        this.paginationBox = paginationBox;
        this.resultsBox = resultsBox;
        this.shownResultsSize = shownResultsSize;
        this.google = google;
    },

    getSearchURL: function (searchParams: SearchParams) {
        let searchURL = (searchParams.providerType === 'r' ? this.restaurantSearchURL : this.searchURL);
        return searchURL + '?' + this.getURLParamsOuter(true, searchParams);
    },

    getInnerSearchURL: function (searchParams: SearchParams) {
        return this.innerSearchURL + '?' + this.getURLParamsOuter(true, searchParams);
    },

    getURLParams: function (searchParams: SearchParams) {
        let result = 'q=';
        let query = '';
        let $ = jQuery;
        $('.search-input-alt').each(function () {
            let val = $(this).val();
            if (typeof val === 'string') {
                query = val;
            }
        });
        result += encodeURIComponent(query);
        //var categoryVal = $('select[id=input-category]').val();
        if (searchParams.categoryVal !== undefined) {
            result += '&category=' + searchParams.categoryVal;
        }
        if (searchParams.chainVal !== undefined) {
            result += '&chain=' + searchParams.chainVal;
        }
        if (searchParams.parentCategoryVal !== undefined) {
            result += '&parentcategory=' + searchParams.parentCategoryVal;
        }
        let numPeopleInput = $('input[id=input-num-people]');
        if (numPeopleInput.length > 0) {
            result += '&cinema_capacity_number=' + numPeopleInput.val();
        }
        //result += '&chain=' + $('select[id=input-chain]').val();
        // result += '&date=' + $('input[id=start-date]').val();
        if (searchParams.addToHotelRequestId) {
            result += '&add_to_request_id=' + searchParams.addToHotelRequestId;
        }
        if (searchParams.providerType) {
            result += '&type=' + searchParams.providerType;
        }
        if (searchParams.addMore) {
            result += '&add_more=1';
        }
        let conferenceCapacity = $('select[id=capacity]').val();
        if (conferenceCapacity && conferenceCapacity != '0') {
            result += '&capacity=' + conferenceCapacity;
        }
        let rooms = $('select[id=rooms]').val();
        if (rooms && rooms != '0') {
            result += '&rooms=' + rooms;
        }
        let experiences = $('select[id=experiences]').val();
        if (experiences && experiences != '0') {
            result += '&experiences=' + experiences;
        }
        return result;
    },

    getURLParamsOuter: function (includeStart: boolean, searchParams: SearchParams) {
        let result = this.getURLParams(searchParams);
        if (includeStart) {
            result += '&start=' + this.startResult;
        }
        if (this.mapLatLngNE) {
            result += '&lathi=' + this.mapLatLngNE.lat() + '&latlo=' + this.mapLatLngSW.lat() +
                '&lnghi=' + this.mapLatLngNE.lng() + '&lnglo=' + this.mapLatLngSW.lng() + '&zoom=' + this.prevZoom;
        }

        return result;
    },

    doSearch: function (searchParams: SearchParams) {
        let searchURL = (searchParams.providerType === 'r' ? this.restaurantSearchURL : this.searchURL);
        window.location.href = searchURL + '?' + this.getURLParams(searchParams);
    },

    doSearchOuter: function (searchParams: SearchParams) {
        //startResult = 0;
        /*
        this.$('#search_map').animate({
            opacity: '0.5'
        }, 100);
         */
        this.doSearchInner(false, searchParams);
    },

    doSearchInner: function (doUpdateMap: boolean, searchParams: SearchParams) {
        this.$('#search-results-spinner').show();
        this.$('#main-list-grid,#pagination').animate({
            opacity: '0.5'
        }, 100);
        let that = this;
        this.$.getJSON(this.getInnerSearchURL(searchParams), function (data: SearchResponse) {
            // Hide results spinner

            that.$('#search-results-spinner').hide();
            that.$('#main-list-grid,#pagination').animate({
                opacity: '1.0'
            }, 100);

            /*
            // Check for redirect

            if (data.redirect_url !== null) {
                window.location.href = data.redirect_url;
            }
            */

            // Show current results
            that.paginationBox.html(that.$(data.pagination_html));
            //that.mapCenter = data.map_center;

            // Update number of rooms as the query may have overridden the existing value
            if (data.cinema_capacity_number !== 0) {
                that.$('input[id=input-num-people]').val(data.cinema_capacity_number);
            }
            that.updateSearchResults(data.hits, data.hits.length, that.$(data.html), searchParams);
            if (doUpdateMap) {
                that.updateMap(data.hits, data.hits.length, that.$(data.html), searchParams);
            }

            that.$('#search-results-box').animate({
                opacity: '1.0'
            }, 100);
            if (doUpdateMap) {
                that.$('#search_map').animate({
                    opacity: '1.0'
                }, 100);
            }
        });
    },

    updateSearchResults: function (hits: SearchResult[], numResults: number, resultsHTML: JQuery, searchParams: SearchParams) {
        this.resultsBox.empty();
        if (hits.length <= 0) {
            this.resultsBox.append(
                '<p class="lead text-center" style="padding-top: 40px"></p><p class="lead text-center">' +
                '<a href="' + this.askUsURL + '">Kontakt oss!</a><br>' +
                '<a href="' + this.findURL + (searchParams.addToHotelRequestId ? '?add_to_request_id=' + searchParams.addToHotelRequestId.toString() : '') + '">Til forsiden</a>');
            let query = this.$('input[name=q]').val();
            let text = 'Søket ';
            if (query !== '') {
                text += 'på "' + query + '" ';
            }
            text += 'ga ingen treff, men vi i Meetings kan hjelpe deg med andre forslag.';

            this.resultsBox.text(text);
        }
        for (let i = this.startResult; i < this.startResult + this.shownResultsSize; i++) {
            if (i < numResults) {
                this.resultsBox.append(resultsHTML.filter('a[id=hotel-' + hits[i].id + ']'));
            }
        }

        // Handle left and right button activation
        if (!this.firstSearch) {
            if (this.startResult > 0) {
                this.$('.left-button').parent().removeClass('disabled');
            } else {
                this.$('.left-button').parent().addClass('disabled');
            }

            if (this.startResult + this.shownResultsSize >= numResults) {
                this.$('.right-button').parent().addClass('disabled');
            } else {
                this.$('.right-button').parent().removeClass('disabled');
            }

            // Change active page indicator

            let activePage = Math.floor(this.startResult / this.shownResultsSize) + 1;
            this.$('a[class^=page-button-]').parent().removeClass('active');
            this.$('.page-button-' + activePage).parent().addClass('active');
        }

        // Update search results info

        if (hits.length > 0) {
            this.$('#search-results-info').html(
                (this.startResult + 1) + ' - ' + Math.min(this.startResult + this.shownResultsSize, numResults) + ' ' + 'av' +
                ' ' + numResults + ' ' + 'resultater');
            this.$('#results-navigation-row').show();
            this.$('#search_map').show();
        } else {
            this.$('#results-navigation-row').hide();
            this.$('#search_map').hide();
        }

        if (!this.firstSearch) {
            this.storePageState(searchParams);
        }
        this.firstSearch = false;

    },

    updateMap: function (hits: SearchResult[], numResults: number, resultsHTML: JQuery, searchParams: SearchParams) {
        let searchMap = this.$('#search_map');
        let that = this;
        if (hits.length <= 0) {
            //$('#map-spinner').hide();
            //searchMap.hide();
            return;
        }
        searchMap.show();

        if (this.mapLatLngNE) {
            let latLngBounds = new this.google.maps.LatLngBounds(this.mapLatLngSW, this.mapLatLngNE);
            this.map = this.createMap(latLngBounds.getCenter(), this.prevZoom);
        } else {
            // Parse the map center string
            let latLng = this.parseMapString(this.mapCenter);
            this.map = this.createMap(new this.google.maps.LatLng(latLng[0], latLng[1]), 12);
        }

        this.google.maps.event.addListenerOnce(this.map, 'tilesloaded', function () {
            that.addMarkers(hits, numResults, resultsHTML, searchParams);
        });

    },

    createMap: function (center: google.maps.LatLng, zoom: number) {
        return new this.google.maps.Map(document.getElementById('search_map'), {
            center: center,
            zoom: zoom,
            mapTypeId: this.google.maps.MapTypeId.ROADMAP
        });
    },

    addMarkers: function (hits: SearchResult[], numResults: number, resultsHTML: JQuery, searchParams: SearchParams) {
        let that = this;
        let markerBounds = new this.google.maps.LatLngBounds();

        // Use a shared info window for all markers
        let infoWindow = new this.google.maps.InfoWindow();

        for (let i = 0; i < hits.length; i++) {
            if (hits[i].map === '' || hits[i].map === null) {
                continue;
            }

            let latLng = this.parseMapString(hits[i].map);

            let point = new this.google.maps.LatLng(latLng[0], latLng[1]);
            markerBounds.extend(point);

            let marker = new this.google.maps.Marker({
                position: point,
                map: this.map,
                infoWindowTitle: '<a href="/konferanse/' + hits[i].slug + '/">' + hits[i].name + '</a>',
                title: hits[i].name,
                internalId: i,
                hotelId: hits[i].id
            });

            // Handle marker clicks
            this.google.maps.event.addListener(marker, 'click', function () {
                // Close existing info window and reopen with new content

                infoWindow.close();
                infoWindow.setContent(this.infoWindowTitle);
                infoWindow.open(that.map, this);

                // Check if we need to update the search results

                let doUpdate = false;
                while (this.internalId < that.startResult || this.internalId >= that.startResult + that.shownResultsSize) {
                    doUpdate = true;
                    if (this.internalId < that.startResult) {
                        that.startResult -= that.shownResultsSize;
                    } else {
                        that.startResult += that.shownResultsSize;
                    }
                }

                if (doUpdate) {
                    that.updateSearchResults(hits, numResults, resultsHTML, searchParams);
                }

                // Do a quick pulse of the associated search result box

                let hotelResultBox = that.$('li[id=hotel-' + this.hotelId + ']');
                hotelResultBox.animate({
                        opacity: '0.5'
                    }, 200, function () {
                        hotelResultBox.animate({
                            opacity: '1.0'
                        }, 200);
                    }
                );
            });
        }
        if (!this.mapLatLngNE) {
            this.map.fitBounds(markerBounds);

            this.google.maps.event.addListenerOnce(this.map, 'bounds_changed', function () {
                if (this.getZoom() > 15) {
                    that.ignoreZoomEvent = true;
                    this.setZoom(15);
                }
            });

        }

        // Close info window on map click
        this.google.maps.event.addListener(this.map, 'click', function () {
            infoWindow.close();
        });

        function storeBoundsAndZoom() {
            let latLngBounds = that.map.getBounds();
            that.mapLatLngNE = latLngBounds.getNorthEast();
            that.mapLatLngSW = latLngBounds.getSouthWest();
            that.prevZoom = that.map.getZoom();
        }

        // Redo search after dragging and zooming map

        this.google.maps.event.addListener(this.map, 'dragend', function () {
            storeBoundsAndZoom();
            that.startResult = 0;
            that.doSearchOuter(searchParams);
        });

        this.google.maps.event.addListener(this.map, 'zoom_changed', function () {
            if (!that.ignoreZoomEvent) {
                storeBoundsAndZoom();
                that.startResult = 0;
                that.doSearchOuter(searchParams);
            } else {
                that.ignoreZoomEvent = false;
            }
        });
    },

    parseMapString: function (mapString: string) {
        let mapStringElems = mapString.split(',');
        let lat = parseFloat(mapStringElems[0]);
        let lng = parseFloat(mapStringElems[1]);
        return [lat, lng];
    },

    storePageState: function (searchParams: SearchParams) {
        // Store page state to solve Chrome reload when back button is pressed
        window.history.replaceState('start' + this.startResult, '', this.getSearchURL(searchParams));
    }

};
